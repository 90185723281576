$(document).ready(function() {

    if ($('#map-div').length) {


        google.maps.event.addDomListener(window, 'load', map_init);

    }

    function map_init() {

        var browser_size = $( window ).width();

        if (browser_size > 760) {
            var zoom_set = 9;
            var drag = true;
            var scroll_wheel = true;

        }
        else {
            var zoom_set = 11;
            var drag = false;
            var scroll_wheel = false;
        }

        var acLatlng = new google.maps.LatLng(48.8548962,2.4469695999999885);

        var mapOptions = {

            zoom: zoom_set,
            panControl: false,
            zoomControl: true,
            mapTypeControl: false,
            scrollwheel: scroll_wheel,
            streetViewControl: true,
            center: acLatlng,
            draggable: drag,

        };

        var mapElement = document.getElementById('map-div');
        var map = new google.maps.Map(mapElement, mapOptions);

        var contentString = '<h4><img src="/img/icons/favicon-32x32.png">&nbsp;&nbsp;SUPPAC</h4> 97-99, rue de Stalingrad - BP 143<br>93103 Montreuil Cedex (France)';

        var infowindow = new google.maps.InfoWindow({
            content: contentString
        });


        var marker = new google.maps.Marker({

            position: acLatlng,
            map: map
            // icon: base_url + 'img/map_marker.png'

        });

        marker.addListener('click', function() {
            infowindow.open(map, marker);
            map.setZoom(10);
            map.setCenter(marker.getPosition());
        });

    }

    $('.TopSlider').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: false,
        autoplay: true,
        autoplaySpeed: 3000,
        appendDots: '.TopSlider__dots',
        arrows: false
    });


});




